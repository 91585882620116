.loaderWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(8px);
  z-index: 20;
}

.loader {
  width: 150px;
  height: 150px;
  position: fixed;
  top: calc(50% - 75px);
  right: calc(50% - 75px);
  border: 20px solid #f5f5f5;
  border-color: #f5f5f5 #f5f5f5 #f5f5f5 var(--color-brand-main-700);
  border-radius: 50%;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}