.tile-picker {
  text-align: center;
  font-weight: 700;
  display: flex;
  gap: 16px;
}

.tile-picker > .card {
  height: 10rem;
  width: 100%;
  cursor: pointer;
}

.tile-picker > .card > .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tile-picker > .card:hover {
  --bs-card-bg: var(--color-brand-main-0);
}

.tile-picker > .card.selected {
  --bs-card-border-color: var(--color-brand-main-700);
  --bs-card-bg: var(--color-brand-main-100);
  color: var(--color-brand-main-700);
}