
@import './variables.scss';

// Import Bootstrap
@import '~bootstrap/scss/bootstrap';


:root {
  --bs-primary-rgb: 81, 61, 98;
  --bs-primary: #604C8D;
  --bs-primary-hover: #665376;
  --bs-body-font-family: "Lexend", sans-serif;
  --bs-border-radius: 0.5rem;
  --bs-border-color: var(--color-brand-neutral-300);
}

h5, .h5 {
  font-size: 1.375rem;
}

hr {
  border-color: var(--color-brand-neutral-100);
  opacity: 1;
}

small,.small {
  font-size: 0.75em;
  color: #808080;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}


/***************** CARD *****************/

.card {
  --bs-card-border-color: var(--color-brand-neutral-300);
}

/***************** CARD *****************/

/***************** RADIO INPUT *****************/

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  cursor: pointer;
  position: relative;
  margin-left: 32px;
}

input[type="radio"] + label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border: 2px solid var(--color-brand-neutral-900);
  border-radius: 50%;
  left: -2rem;
  top: calc(50% - 0.5rem);
  transition: border-color 400ms ease;
}

input[type="radio"] + label::after {
  content: "";
  position: absolute;
  width: 0.85rem;
  height: 0.85rem;
  background-color: var(--color-brand-main-700);
  border-radius: 50%;
  top: 50%;
  left: -1.91rem;
  transform: translateY(-50%) scale(0);
  transition: transform 300ms ease;
}

input[type="radio"]:checked+label {
  color: var(--color-brand-main-700);
}

input[type="radio"]:checked+label::before {
  border-color: var(--color-brand-main-700);
}

input[type="radio"]:checked+label::after {
  transform: translateY(-50%) scale(0.55);
}

/***************** RADIO INPUT *****************/

/***************** FORM CONTROL *****************/
.form-control {
  padding: 1rem 1.5rem;
  line-height: 1.375;
}

.form-control:focus {
  border-color: var(--color-brand-main-700);
  box-shadow: 0 0 0 0.25rem rgba(96, 76, 141, 0.25);
}

.is-invalid > .invalid-feedback {
  display: block;
}

.form-check-input, .form-check-input:checked, .form-check-input:focus {
  background-color: transparent;
  border-color: var(--color-brand-main-900);
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data: image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' %3E%3Cpath fill='none' stroke='%232C2046' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6' /%3E%3C/svg%3E");
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(44, 32, 70, 0.25);
}

/***************** FORM CONTROL *****************/


/***************** BUTTONS *****************/

.btn {
  --bs-btn-padding-x: 2rem;
  --bs-btn-padding-y: 0.875rem;
  --bs-btn-font-weight: 500;
  max-width: 382px;
  border-radius: 1rem;
}

.btn-primary {
    --bs-btn-bg: var(--color-brand-main-700);
    --bs-btn-border-color: var(--color-brand-main-700);
    --bs-btn-hover-bg: var(--color-brand-main-900);
    --bs-btn-hover-border-color: var(--color-brand-main-900);
    --bs-btn-active-bg: var(--color-brand-main-900);
    --bs-btn-active-border-color: var(--color-brand-main-900);
    --bs-btn-disabled-bg: var(--color-brand-main-900);
    --bs-btn-disabled-border-color: var(--color-brand-main-900);
}

.btn-secondary {
  --bs-btn-color: var(--color-brand-main-900);
  --bs-btn-bg: var(--secondary-orange-base-500);
  --bs-btn-border-color: var(--secondary-orange-base-500);
  --bs-btn-hover-color: var(--color-brand-main-900);
  --bs-btn-hover-bg: var(--secondary-orange-base-400);
  --bs-btn-hover-border-color: var(--secondary-orange-base-400);
  --bs-btn-active-color: var(--color-brand-main-900);
  --bs-btn-active-bg: var(--secondary-orange-base-400);
  --bs-btn-active-border-color: var(--secondary-orange-base-400);
  --bs-btn-disabled-color: var(--color-brand-main-900);
  --bs-btn-disabled-bg: var(--secondary-orange-base-400);
  --bs-btn-disabled-border-color: var(--secondary-orange-base-400);
}

.btn-link {
  --bs-btn-color: var(--color-brand-neutral-900);
  --bs-btn-hover-color: var(--color-brand-main-700);
  --bs-btn-active-color: var(--color-brand-main-700);
  text-decoration: none;
}

/***************** BUTTONS *****************/

/***************** CONTAINER *****************/
.container {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 3rem;
  padding: calc(var(--bs-gutter-y) * 0.5) calc(var(--bs-gutter-x) * 0.5);
}
/***************** CONTAINER *****************/



/***************** PROGRESS BAR *****************/

.progress, .progress-stacked {
  --bs-progress-border-radius: 0;
  --bs-progress-height: 0.5rem;
  --bs-progress-bar-bg: var(--color-brand-main-700);
}


.progress > .progress-bar {
  border-radius: 0px 600px 600px 0px;
}


/***************** PROGRESS BAR *****************/

/***************** TYPOGRAPHY *****************/

.text-purple-dark {
  color: var(--color-brand-main-900);
}

/***************** TYPOGRAPHY *****************/

/***************** MODAL *****************/

@media (min-width: 576px) {
  .modal-sm {
    --bs-modal-width: 340px;
  }
}

/***************** MODAL *****************/

@media (min-width: 575.98px) {
  .flex-xxs-nowrap {
    flex-wrap: nowrap!important;
  }
}
