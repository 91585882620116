.radio-list > .card {
  cursor: pointer;
}

.radio-list > .card:hover {
  --bs-card-bg: var(--color-brand-main-0);
}

.radio-list > .card.selected {
  --bs-card-border-color: var(--color-brand-main-700);
  --bs-card-bg: var(--color-brand-main-100);
}