.page-not-found {
  height: 100%;
  background-color: var(--color-background-base);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  color: var(--color-brand-main-700);
  text-align: center;
  overflow: hidden;
}

.page-not-found > .code {
  font-size: calc(8rem + 1.5vw);
  font-weight: 500;
}