.home-page {
  padding: 16px 0;
  min-height: calc(100vh - 235px);
  position: relative;
}

.home-page > .background {
  position: absolute;
  top: -380px;
  left: -150px;
  z-index: 0;
}

.home-page .title {
  font-family: "Family", sans-serif;
  font-size: 64px;
  font-weight: 500;
  color: var(--color-brand-main-900);
}

.home-page .container {
  position: relative;
  z-index: 1;
  margin: 8% auto;
}

.home-page .left-panel {
  height: 100%;
  margin-top: 120px;
  width: 424px;
  max-width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;  
  flex-direction: column;
  text-align: left;
  gap: 12px;
}

@media (max-width: 575.98px) {
  .home-page {
    background-position: center -32px;
    background-size: clamp(320px, 100%, 420px);
  }

  .home-page .title {
    font-size: 36px;
  }

  .home-page > .background {
    top: -390px;
    left: 200px;
  }

  .home-page > .container {
    margin: auto;
  }
}

