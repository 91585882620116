.scroll-to-top-button {
  opacity: 0;
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 40px;
  height: 40px;
  padding: 0;
  transition: opacity .3s;
  z-index: 10;
}

.scroll-to-top-button.visible {
  opacity: 1;
}