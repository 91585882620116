:root {  
  --color-danger: #e02f49;
  --color-success: #0D7C7B;
  --color-warning: #f29c43;

  --color-brand-neutral-100: #E0E1E3;
  --color-brand-neutral-300: #D1D2D4;
  --color-brand-neutral-700: #788087;
  --color-brand-main-0: #F0F8FF;
  --color-brand-main-100: #DDF0FE;
  --color-brand-main-700: #255287;
  --color-brand-main-900: #002859;
  --color-brand-neutral-900: #23262A;
  --color-attachment-anxious-300: #FFE7CF;
  --color-background-base: #FFFFFF;

  --secondary-orange-base-400: #f1bba2;
  --secondary-orange-base-500: #FEB290;

  --toastify-color-success: var(--color-success);
  --toastify-color-warning: var(--color-warning);
  --toastify-color-error: var(--color-danger);
  
  --toastify-toast-width: 440px;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  /* ExtraBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Family';
  src: url('../fonts/Family-Heavy.woff2') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Family';
  src: url('../fonts/Family-Bold.woff2') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
  color: var(--color-brand-neutral-900);
  background-color: #FDFDFF;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px!important;
}

.fs-15 {
  font-size: 15px!important;
}

.mw-260 {
  max-width: 260px!important;
}

