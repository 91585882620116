#workplace-disclaimer .modal {
    --bs-modal-header-border-width: 0;
    --bs-modal-footer-border-width: 0;
  }
  
  #workplace-disclaimer .modal-content {
    border-radius: 16px 16px 28px 16px;
    background: #f0f8ff;
    box-shadow: 0px 10px 20px 0px rgba(26, 16, 50, 0.20);
  }
  
  #workplace-disclaimer .modal-header {
    font-weight: 500;
    font-size: 20px;
  }
  
  #workplace-disclaimer .btn-close {
    background: transparent var(--bs-btn-close-bg) center/0.8rem auto no-repeat;
    box-shadow: unset;
  }

  #workplace-disclaimer .form-check-label a {
    color: var(--bs-modal-color);
  }
  
  #workplace-disclaimer .btn-agree {
    width: 215px;
    max-width: 100%;
  }
  
  @media (max-width: 991.98px) {
    #workplace-disclaimer .btn-agree {
      width: 100%;
      margin-top: 24px;
    }
  }

  @media (min-width: 991.98px){
    #workplace-disclaimer .modal-footer > div{
      max-width: 70%;
    }
  }