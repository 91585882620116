.unsubscribe-page {
  height: 75vh;
  display: flex;
  padding: 64px 0;
}

.unsubscribe-page > .success-message {
  margin: auto;
  text-align: center;
  color: var(--color-brand-main-700);
}

.unsubscribe-page > .success-message > img {
  max-width: 200px;
}