header.header {
  position: relative;
  background-color: var(--color-brand-main-0);
  z-index: 1;
}

header.header .title {
  font-size: 16px;
  font-weight: 700;
  text-align: end;
  margin: 0 0 0 auto;
}

header .container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}