.range-slider-wrapper {
  text-align: center;
}

.range-slider-wrapper > label {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-brand-neutral-300);
  margin-top: 10px;
  margin-bottom: 36px;
}

.range-slider-wrapper.active > label {
  color: var(--color-brand-main-700);
}

.range-slider-wrapper > .range-slider {
  color: var(--color-brand-neutral-700);
  font-size: 14px;
  position: relative;
}

.range-slider-wrapper > .range-slider > input {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  background: transparent;
  outline: none;
  position: relative;
  z-index: 9;
}

.range-slider-wrapper > .range-slider > input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 48px;
  height: 42px;
  border-radius: 60px;
  margin: 32px 0;
  background-color: var(--color-brand-neutral-700);
  background-image: url('../../assets/icons/pause.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 0.3s;
}

.range-slider-wrapper:hover >.range-slider>input::-webkit-slider-thumb,
.range-slider-wrapper.active > .range-slider > input::-webkit-slider-thumb {
  background-color: var(--color-brand-main-700);
}

.range-slider-wrapper > .range-slider > input::-moz-range-thumb {
  width: 48px;
  height: 42px;
  border-radius: 60px;
  margin: 32px 0;
  background-color: var(--color-brand-neutral-700);
  background-image: url('../../assets/icons/pause.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 0.3s;
}

.range-slider-wrapper:hover > .range-slider > input::-moz-range-thumb,
.range-slider-wrapper.active > .range-slider > input::-moz-range-thumb {
    background-color: var(--color-brand-main-700);
}

.range-slider-wrapper > .range-slider > input::-moz-range-track {
  background: transparent;
  height: 12px;
}

.range-slider-wrapper > .range-slider > .dot-wrapper {
  height: 12px;
  border-radius: 60px;
  background-color: #FBE9D0;;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 3px 20px;
  margin-top: 4px;
}

.range-slider-wrapper > .range-slider > .dot-wrapper,
.range-slider-wrapper > .range-slider > .tick-wrapper {
  display: flex;
  justify-content: space-between;
}

.range-slider-wrapper > .range-slider > .tick-wrapper {
  padding: 20px;
}

.tick-wrapper > .tick {
  max-width: 6px;
  display: flex;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .range-slider-wrapper > .range-slider {
    font-size: 10px;
  }
}

@media (max-width: 340px) {
  .range-slider-wrapper>.range-slider {
    font-size: 8px;
  }
}