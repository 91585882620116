.introduction {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: "Lexend";
  margin-bottom: 36px;
}

.introduction > .cover {
  text-align: center;
  min-height: 335px;
}

.introduction > .cover > img {
  max-width: 100%;
  height: auto;
}

.introduction > .title {
  font-size: 28px;
  font-weight: 500;
}

.introduction > .action {
  max-width: unset;
}

@media (max-width: 575.98px) {
  .introduction>.cover {
    min-height: unset;
  }
}