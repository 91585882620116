.question-container.auto-highlight > .question-wrapper {
  padding-top: 32px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.question-container.auto-highlight>.question-wrapper > .question {
  padding-bottom: 32px;
}

.question-container.auto-highlight > .question-wrapper.active {
  opacity: 1;
  transition: opacity 0.3s;
}

@media (hover: hover) {
  .question-container.auto-highlight:has(.question-wrapper:hover) > .question-wrapper.question-wrapper.active {
    opacity: 0.5;
  }

  .question-container.auto-highlight > .question-wrapper:hover {
    opacity: 1!important;
    transition: opacity 0.3s;
  }
}