.footer {
  width: 100%;
  background-color: var(--color-brand-main-900);
  z-index: 1;
  color: #FFF;
  position: relative;
  font-size: 14px;
  -webkit-backface-visibility: hidden;
}

.link {
  color: #FFF;
}

.disclaimer {
  opacity: 0.5;
  margin-top: 40px;
}