.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  max-width: 200px;
}

.content {
  max-width: 576px;
  margin-top: 32px;
  color: var(--color-brand-main-700);
}
